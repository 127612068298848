import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kevin/Documents/GitHub/credit101-react/src/components/blog-post-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We want to make it easier for you to look up credit card terms, so we've credit a credit card glossary with 50 common terms in it. We'll be updating this list regularly in case we missed something. In our future articles, we'll also be linking certain terms to their definitions on this page for your convenience. Note that this article only contains terms relevant to credit cards, not credit score. If you want to learn about the vocabulary specific to credit score, we have another article just for that.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Related: `}<a parentName="p" {...{
          "href": "https://credit101.net/score/credit-score-glossary"
        }}>{`Credit Score Glossary (30 Terms)`}</a></p>
    </blockquote>
    <h2>{`A`}</h2>
    <div className="px-8" id="annual-fee"><label><strong>Annual Fee</strong>: For credit cards with annual fees, you pay an annual fee to keep the card and its benefits.</label></div>
    <div className="px-8" id="authorized-user"><label><strong>Authorized User</strong>: A person authorized to use another person credit card but is not legally liable for paying the bills.</label></div>
    <div className="px-8" id="available-credit"><label><strong>Available Credit</strong>: The remaining amount of credit you can use before paying off your balance.</label></div>
    <h2>{`B`}</h2>
    <div className="px-8" id="balance"><label><strong>Balance</strong>: The amount you owe to the card issuer.</label></div>
    <div className="px-8" id="balance-transfer"><label><strong>Balance Transfer</strong>: The transfer of balance from one account (usually with a higher interest rate) to another (usually the one with a lower interest rate).</label></div>
    <div className="px-8" id="bonus"><label><strong>Bonus</strong>: Additional reward (in points or cash back) you get from completing a credit card offer.</label></div>
    <div className="px-8" id="business-credit-card"><label><strong>Business Credit Card</strong>: A credit card intended for the use of a business.</label></div>
    <div className="px-8" id="billing-cycle"><label><strong>Billing Cycle</strong>: Days between your two statement dates.</label></div>
    <div className="px-8" id="billing-statement"><label><strong>Billing Statement</strong>: A monthly report of your account activities.</label></div>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block"
      }} data-ad-client="ca-pub-3524456596508824" data-ad-slot="5342728383" data-ad-format="auto" data-full-width-responsive="true">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`C`}</h2>
    <div className="px-8" id="cash-back"><label><strong>Cash Back</strong>: A way credit card issuers reward you for each transaction you make. Usually, it's a percentage of the transaction amount.</label></div>
    <div className="px-8" id="cash-advance"><label><strong>Cash Advance</strong>: Withdrawing cash with your credit card. We highly advise against doing so as it results in an instant loan. You might be charged a cash advance fee. The amount of cash you withdrew will also follow an increased APR different from other purchases you make on the card.</label></div>
    <div className="px-8" id="credit-n"><label><strong>Credit (noun)</strong>: The ability to obtain goods or service before paying based on the trust that the payment will be made in the future.</label></div>
    <div className="px-8" id="credit-v"><label><strong>Credit (verb)</strong>: A payment made to your account.</label></div>
    <div className="px-8" id="credit-card"><label><strong>Credit Card</strong>: A payment card that can be used to acquire goods or services and pay for them later.</label></div>
    <div className="px-8" id="credit-card-company"><label><strong>Credit Card Company</strong>: A company that issues credit cards. In the United States, such a company must be a bank.</label></div>
    <div className="px-8" id="credit-limit"><label><strong>Credit Limit / Credit Line</strong>: The maximum amount of credit available to you for a particular account. That is, the maximum amount a bank is willing to lend you for that account.</label></div>
    <div className="px-8" id="credit-utilization-rate"><label><strong>Credit Utilization Rate</strong>: Total balance divided by the amount of credit available.</label></div>
    <div className="px-8" id="charge-card"><label><strong>Charge Card</strong>: A type of credit card that requires balance be paid in full within each statement period.</label></div>
    <div className="px-8" id="co-sign"><label><strong>Co-sign</strong>: Signing an agreement to share debt and taking the responsibility to pay all the debt if the other person defaults.</label></div>
    <div className="px-8" id="collection"><label><strong>Collection</strong>: The act of collecting unpaid debts that are past the due date.</label></div>
    <div className="px-8" id="consolidation"><label><strong>Consolidation</strong>: Combining the amount owed to several creditors into a loan which can be paid with a single payment.</label></div>
    <div className="px-8" id="card-act"><label><strong>CARD Act</strong>: A federal law passed to protect consumers from unfair practices of credit card issuers.</label></div>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block",
        "textAlign": "center"
      }} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3524456596508824" data-ad-slot="9281973399">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`D`}</h2>
    <div className="px-8" id="debt"><label><strong>Debt</strong>: The money owed.</label></div>
    <div className="px-8" id="due-date"><label><strong>Due Date</strong>: The day your credit card payment is due. While different issuers might have grace periods, accounts are generally considered delinquent when payments are not made past the due date.</label></div>
    <div className="px-8" id="delinquency"><label><strong>Delinquency</strong>: Failure to pay outstanding debt.</label></div>
    <div className="px-8" id="default"><label><strong>Default</strong>: Failure to pay outstanding debt for an extended period of time. After several periods of delinquency, the creditor will likely put your account in default. (Default is very serious and can be hard to get out of.)</label></div>
    <div className="px-8" id="dispute"><label><strong>Dispute</strong>: When you think a charge has been made to your account erroneously, you can file a dispute with the issuer within 60 days of you receiving the erroneous statement.</label></div>
    <h2>{`E`}</h2>
    <div className="px-8" id="emv"><label><strong>EMV</strong>: A technology that uses a chip of the credit card to generate a one-time authorization code for each transaction. Modern credit cards all have EMV chips embedded.</label></div>
    <div className="px-8" id="extended-warranty"><label><strong>Extended Warranty</strong>: A benefit that comes with some credit cards that extended the warranty of products you purchase with the card.</label></div>
    <h2>{`F`}</h2>
    <div className="px-8" id="foreign-transaction-fee"><label><strong>Foreign Transaction Fee</strong>: Some cards charge a fee when you use the card abroad.</label></div>
    <h2>{`G`}</h2>
    <div className="px-8" id="grace-period"><label><strong>Grace Period</strong>: The days during which interest or fees are not charged when new purchases are made using the credit card. Grace periods do not apply to cash advances.</label></div>
    <h2>{`I`}</h2>
    <div className="px-8" id="introductory-apr"><label><strong>Introductory APR</strong>: The APR you're charged within the first few months of opening a credit card. Usually, to attract new customers, issuers tend to offer low or 0% APR. After, the APR will adjust to normal rates.</label></div>
    <div className="px-8" id="introductory-annual-fee"><label><strong>Introductory Annual Fee</strong>: Sometimes, credit card issuers offer lowered or $0 annual fee for the first year for cards with annual fees.</label></div>
    <div className="px-8" id="interest"><label><strong>Interest</strong>: The amount paid at a rate for the use of credit.</label></div>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block",
        "textAlign": "center"
      }} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3524456596508824" data-ad-slot="9281973399">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`L`}</h2>
    <div className="px-8" id="late-payment"><label><strong>Late Payment</strong>: Payments made past due dates. Late payments will usually result in a late payment fee and be reflected on your credit score.</label></div>
    <div className="px-8" id="late-payment-fee"><label><strong>Late Payment Fee</strong>: Additional fee incurred when payments are made late (past due date).</label></div>
    <h2>{`M`}</h2>
    <div className="px-8" id="minimum-payment"><label><strong>Minimum Payment</strong>: The minimum amount you need to pay by the due date without breaching cardholder agreement (which leads to default).</label></div>
    <h2>{`N`}</h2>
    <div className="px-8" id="no-preset-spending-limit"><label><strong>No Preset Spending Limit</strong>: For charge cards and some credit cards, there is not a spending limit or credit line. Instead, transactions are approved on a case-by-case basis. </label></div>
    <h2>{`P`}</h2>
    <div className="px-8" id="no-preset-spending-limit"><label><strong>No Preset Spending Limit</strong>: For charge cards and some credit cards, there is not a spending limit or credit line. Instead, transactions are approved on a case-by-case basis. </label></div>
    <div className="px-8" id="pin"><label><strong>PIN</strong>: A combination you use to authorize payment. Sometimes, PINs are used instead of your signature. </label></div>
    <div className="px-8" id="pre-approval"><label><strong>Pre-Approval</strong>: Sometimes, issuers pull your credit score from credit bureaus to give you credit card offers that you'll likely be approved for. Pre-approved offers do not guarantee approval. Also, under the CARD Act, you can opt out of pre-approval programs. Young adults under 21 are also not allowed to be targeted for pre-approved offers unless they explicitly enroll in writing.</label></div>
    <div className="px-8" id="principle"><label><strong>Principle</strong>: Principle is the amount of money actually borrowed (which doesn't include interest).</label></div>
    <div className="px-8" id="prime-rate"><label><strong>Prime Rate</strong>: The base interest rate set by individual banks according to the federal funds rate.</label></div>
    <div className="px-8" id="payment-network"><label><strong>Payment Network</strong>: A network through which payment card transactions are processed.</label></div>
    <div className="px-8" id="price-protection"><label><strong>Price Protection</strong>: When you purchase a product and find the product is offered at a lower price elsewhere, some credit cards offering this benefit will refund you the price difference.</label></div>
    <h2>{`Q`}</h2>
    <div className="px-8" id="quarterly-categories"><label><strong>Quarterly Categories</strong>: For some cash back cards, you get increased cash back for using the card in certain payments categories (such as gas stations or grocery stores). </label></div>
    <h2>{`R`}</h2>
    <div className="px-8" id="return-protection"><label><strong>Return Protection</strong>: Some credit cards offer return protection which allows you to return goods if the vendor doesn't accept the return. </label></div>
    <h2>{`S`}</h2>
    <div className="px-8" id="secured-card"><label><strong>Secured Card</strong>: A type of credit card that requires a security deposit as collateral. The deposit is usually a percentage of the total available credit given. Secured cards are great for people with poor credit to build credit.</label></div>
    <div className="px-8">
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
  <ins className="adsbygoogle" style={{
        "display": "block",
        "textAlign": "center"
      }} data-ad-layout="in-article" data-ad-format="fluid" data-ad-client="ca-pub-3524456596508824" data-ad-slot="9281973399">
  </ins>
  <script dangerouslySetInnerHTML={{
        __html: "(adsbygoogle = window.adsbygoogle || []).push({});"
      }}></script>
    </div>
    <h2>{`T`}</h2>
    <div className="px-8" id="transaction-fee"><label><strong>Transaction Fee</strong>: Fee charged for using the card for certain transactions such as ATM withdrawals.</label></div>
    <h2>{`V`}</h2>
    <div className="px-8" id="extended-warranty"><label><strong>Variable Interest Rate</strong>: An interest rate that varies, usually depending on the Prime Rate.</label></div>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      